import React from "react";
import styled from "@emotion/styled";
import { graphql } from "gatsby";

import Layout from "../components/layout/layout";
import Container from "../components/container";
import FooterCta from '../components/FooterCta';
import Separator from '../components/Separator';

import HubspotForm from "../components/HubspotForm";

interface ILandingPageProps {
  data: {
    topImage: {
      publicURL: string;
    },
    midImage: {
      publicURL: string;
    },
    accentImage: {
      publicURL: string;
    }
  }
}

const Tagline = styled.p<any>`
  font-size: 20px;
  line-height: 24px;
  color: ${props => props.theme.secondaryColor};
  font-family: ${props => props.theme.contentFontFamily};
  font-weight: 400;
  padding: 80px 0 46px 0;
  margin: 0;
`;

const LandingFormWrapper = styled.div<any>`
  border: 1px solid ${props => props.theme.primaryColor};
  background: #fff;
  position: relative;
  max-width: 469px;
  min-height: 620px;
  margin: 0 auto;

  &:before {
    background: none;
    border: 1px solid #1E3961;
    content: " ";
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    pointer-events: none;
    transition: border-color .2s ease-in-out;
  }

  @media (min-width: ${props => props.theme.lg}px) {
    margin: 0 0 40px auto;
  }
`;

const LandingForm = styled.div<any>`
  padding: 57px 27px 37px;
  margin: 0;

  h2 {
    font-size: 19px;
    line-height: 24px;
    font-weight: normal;
    text-align: right;
  }

  p {
    font-size: 14px;
    line-height: 18px;
    margin: 20px auto;
  }

  iframe {
    height: 100%;
  }
`;

const FormInput = styled.input<any>`
  border: 1px solid ${props => props.theme.primaryColor};
  border-radius: 0;
  display: block;
  height: 40px;
  width: 100%;
  margin-bottom: 10px;
`;

const LandingPageTopWrapper = styled.div<any>`
  background-color: ${props => props.theme.backgroundColor};
  background: url(${props => props.image}) no-repeat bottom center;
  background-size: cover;

  @media (min-width: ${props => props.theme.lg}px) {
    background-size: 3500px 1449px;
  }
`;

const LandingPageContent = styled.div<any>`
  h1,
  h2,
  h3 {
    font-weight: normal;
  }

  h1 {
    font-size: 46px;
    line-height: 49px;
    margin-top: 0;
  }

  h2 {
    font-size: 36px;
    margin-top: 50px;
  }

  h3 {
    font-size: 24px;
  }

  p {
    font-family: ${props => props.theme.contentFontFamily};
    font-size: 25px;
    line-height: 30px;
  }

  ul {
    list-style-type: circle;
    padding-left: 40px;
  }

  li {
    font-family: ${prosp => prosp.theme.contentFontFamily};
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 0;
  }

  a {
    color: ${props => props.theme.secondaryColor};
  }

  @media (min-width: ${props => props.theme.lg}px) {
    float: left;
    max-width: 50%;
  }
`;

const PageContentWhite = styled(LandingPageContent)<any>`
  float: none;
  padding: 50px 0 70px;
  max-width: 100%;
  background: #fff;

  p {
    font-size: 24px;
    line-height: 29px;
  }

  @media (min-width: ${props => props.theme.lg}px) {
    float: none;
    max-width: 100%;
  }
`;

const HeaderImage = styled.img<any>`
  width: 500px;
  margin: 50px auto 50px;
  display: block;
  
  @media (min-width: ${props => props.theme.lg}px) {
    width: auto;
    margin: 50px -60px 0 -150px;
    max-width: 800px;
  }
`;

const Row = styled.div<any>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 1280px) {
    flex-direction: row;
    align-items: center;
  }
`;

export default function HowToLaunchAProduct(props: ILandingPageProps): JSX.Element {

  const FooterCTA = () => {
    return (
      <FooterCta
        title="At Strategica Partners, we help forward-thinking companies launch world-changing products."
        callout="Are you Ready?"
      />
    )
  }

  const onFormSubmitted = () => {
    console.log("submitted");
  }

  const {
    topImage,
    midImage,
    accentImage,
  } = props.data;

  return (
    <Layout
      title="Landing Page 1"
      hideContactFooterBanner
      FooterCTA={FooterCTA}
      landingPage
    >
      <LandingPageTopWrapper image={accentImage.publicURL}>
        <Container>
          <Tagline>How to Launch a Product: The 9-Step Guide</Tagline>
          <LandingPageContent>
            <h1>Break the norm to optimize growth and revenue.</h1>
            <p>Sign-up and download our guide to developing Go-to-Market Strategies that maximize growth.</p>
            <HeaderImage src={topImage.publicURL} alt="Go-to-market catalog booklet"/>
          </LandingPageContent>
          <LandingFormWrapper>
            <LandingForm>
              <h2>Fill out the form below and learn how to launch your next product.</h2>
              <Separator margin="20px 0 40px auto" />
              <HubspotForm formId="4d54215c-467e-4442-bf41-56de06ab4418" onFormSubmitted={onFormSubmitted} />
            </LandingForm>
          </LandingFormWrapper>
        </Container>
      </LandingPageTopWrapper>
      <PageContentWhite>
        <Container>
          <h2>Ready to launch your next Product?</h2>
          <p>Each product is unique and without clear methods for targeting and acquiring customers, you may be left unsure about what you are seeing or why you are seeing it. When entering the market with a new product, it is vital to have a focused action plan to understand the most effective use of valuable time and resources.</p>
          <p><strong>Download our guide</strong> to launching a product and optimizing growth and revenue. Learn the essential steps needed to break the norm and capture full insights into early customer acquisition, sales, and successfully launch your next product.</p>
          <h2>Topics include:</h2>
          <Row>
            <ul>
              <li>Segment Target Markets</li>
              <li>Identify Target Customers</li>
              <li>Develop Actionable Personas</li>
              <li>Determine Brand Positioning</li>
              <li>Define Unique Value Proposition</li>
              <li>Iterate Product Roadmap</li>
              <li>Develop Marketing Strategy</li>
              <li>Optimize Pricing + Onboarding</li>
              <li>Analyze Cohorts</li>
            </ul>
            <img src={midImage.publicURL} alt="Go-to-market ebook preview" />
          </Row>
          <p>Are you ready to Break the Norm and optimize growth and revenue? Download our guide and help your company define the target customers, marketing strategy, brand positioning, product roadmap, and optimal pricing for its next product.</p>
        </Container>
      </PageContentWhite>
    </Layout>
  )
}

export const query = graphql`
{
  topImage: file(relativePath: {eq: "2_brochures_1x.png"}) {
    publicURL
  }
  midImage: file(relativePath: {eq: "2_brochures_open_1x.png"}) {
    publicURL
  }
  accentImage: file(relativePath: {eq: "landing-accent-bg.jpg"}) {
    publicURL
  }
}
`;